import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { RecoilRoot } from 'recoil'

import 'src/styles/global.css'
import 'src/utils/sprintf'
import { DefaultHeader } from '@src/components/DefaultHeader'
import { DefaultScript } from '@src/components/DefaultScript'
import useInit from '@src/hooks/useInit'
import { DevTools } from '@src/components/DevTools'
import { useInterfaceBetweenApp } from '@src/hooks/useInterfaceBetweenApp'
import { datadogNextjs } from 'configs/datadog-config'

const OPEN_GRAPH = {
  type: 'website',
  title: 'title',
  description: 'description',
  url: 'https://www.url.ie/',
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
})

function MyApp({ Component, pageProps }: AppProps<any>) {
  const { initDatadog } = datadogNextjs()
  const { initInterfaceBetween } = useInterfaceBetweenApp()
  useInit(() => {
    initInterfaceBetween()
    initDatadog()
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <RecoilRoot>
            <DefaultHeader />
            <DefaultScript />
            <DefaultSeo openGraph={OPEN_GRAPH} />
            <Component {...pageProps} />
            <ReactQueryDevtools initialIsOpen={false} />
            <DevTools />
          </RecoilRoot>
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
