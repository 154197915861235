import { datadogRum } from '@datadog/browser-rum'

import pkg from 'package.json'

export function datadogNextjs() {
  const initDatadog = () => {
    if (process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? '',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
        site: 'datadoghq.com',
        service: process.env.NEXT_PUBLIC_DATADOG_APPLICATION ?? '',
        env: process.env.NEXT_PUBLIC_MODE ?? '',
        version: pkg.version,
        sessionSampleRate: process.env.NEXT_PUBLIC_SAMPLE_RATE
          ? Number(process.env.NEXT_PUBLIC_SAMPLE_RATE)
          : 100, // default 값을 100으로 설정
        sessionReplaySampleRate: process.env.NEXT_PUBLIC_REPLAY_SAMPLE_RATE
          ? Number(process.env.NEXT_PUBLIC_REPLAY_SAMPLE_RATE)
          : 20, // default 값을 20으로 설정
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [],
      })
      datadogRum.startSessionReplayRecording()
    }
  }

  return {
    initDatadog,
  }
}
