import { useState } from 'react'

import { When } from '../When'

import { DevToolsHalfView } from './DevToolsHalfView'
import { DraggableDevButton } from './DraggableDevButton'

const IS_DEV = process.env.NEXT_PUBLIC_MODE === 'dev'

export const DevTools = () => {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false)

  const handleDevToolsCloseButtonClick = () => {
    setIsDevToolsOpen(false)
  }

  const handleDevToolsOpenButtonClick = () => {
    setIsDevToolsOpen(true)
  }

  return (
    <When if={IS_DEV}>
      <DraggableDevButton onClick={handleDevToolsOpenButtonClick} />
      <DevToolsHalfView
        isDevToolsOpen={isDevToolsOpen}
        onClose={handleDevToolsCloseButtonClick}
      />
    </When>
  )
}
