import { clientSideOnly } from '@src/utils/enviroment/clinetSideOnly'

export type UpdateVisitedHistoryProps = {
  canGoBack: boolean
  actionBarHeight: number
}

export const useInterfaceBetweenApp = () => {
  const initInterfaceBetween = () => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        window.interfaceBetweenSocarAppAndSocarWeb = {
          onPause: () => {},
          onResume: () => {},
          updateVisitedHistory: () => {},
        }
      }
    })
  }

  const setOnPause = (onPause: () => void) => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        initInterfaceBetween()
      }

      window.interfaceBetweenSocarAppAndSocarWeb.onPause = onPause
    })
  }

  const setOnResume = (onResume: () => void) => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        initInterfaceBetween()
      }

      window.interfaceBetweenSocarAppAndSocarWeb.onResume = onResume
    })
  }

  const setUpdateVisitedHistory = (
    updateVisitedHistory: (updateResult?: UpdateVisitedHistoryProps) => void
  ) => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        initInterfaceBetween()
      }

      window.interfaceBetweenSocarAppAndSocarWeb.updateVisitedHistory =
        updateVisitedHistory
    })
  }

  return {
    setOnPause,
    setOnResume,
    setUpdateVisitedHistory,
    initInterfaceBetween,
  }
}
