import { useState } from 'react'
import type { ReactNode } from 'react'

import { createPortal } from 'react-dom'

import { useDidMount } from '@src/hooks/useDidMount'

type PortalProps = {
  children: ReactNode
}

export const Portal = ({ children }: PortalProps) => {
  const [container, setContainer] = useState<Element | null>(null)

  useDidMount(() => {
    if (document) {
      setContainer(document.body)
    }
  })

  return container && createPortal(children, container)
}
